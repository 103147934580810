import Spinner from "../../../../components/Spinner/Spinner";
import { Container } from "./styles/LDBXStyles";
import logo from "./images/ldbx_logo.svg";

const LDBX = () => {
  return (
    <Container>
      <img src={logo} alt="logo" />
      <Spinner />
    </Container>
  );
};

export default LDBX;
