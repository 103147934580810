import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import DasApi from "../../api/DasApi";
import Spinner from "../../components/Spinner/Spinner";
import { StyledMain } from "../../styled/StyledMain";
import { MainContainer } from "../../styled/StyledMainContainer";
import { DasRedirectStyle } from "./styles/DasRedirectStyles";

import LDBX from "./components/ldbx/LDBX";

const DasRedirect = () => {
  const [deviceNotFoundUrl] = useState("/redirect/device-not-found");

  const params = useParams();
  const [searchParams] = useSearchParams();

  const entityCode = searchParams.get("e") || "0000";

  const entityComponents: { [key: string]: JSX.Element } = {
    "0001": <LDBX />,
  };

  useEffect(() => {
    async function validate() {
      const serialNumber = searchParams.get("i");
      if (!serialNumber || serialNumber.length !== 20) {
        window.location.replace("https://www.manage-mii.co.uk");
      } else {
        const cryptogram = searchParams.get("c");

        try {
          const checkDasDeviceResponse = await DasApi.dasCheckDevice(
            serialNumber,
            cryptogram || null
          );

          window.location.replace(checkDasDeviceResponse.redirectUrl);
        } catch (e) {
          window.location.replace(deviceNotFoundUrl);
        }
      }
    }
    validate();
  }, [deviceNotFoundUrl, params.serialNumber, searchParams]);

  return (
    <div>
      <StyledMain>
        <MainContainer>
          {entityComponents[entityCode] || (
            <DasRedirectStyle>
              <Spinner />
              <h3>Powered by DIGISEQ</h3>
            </DasRedirectStyle>
          )}
        </MainContainer>
      </StyledMain>
    </div>
  );
};

export default DasRedirect;
