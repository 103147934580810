import styled from "styled-components";

export const DasRedirectStyle = styled.section`
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;

  h3 {
    margin-top: 40px;
  }
`;
